export const METRICS_LOADING = "METRICS_LOADING";
export const METRICS_SUCCESS = "METRICS_SUCCESS";

export type Metrics = {
  AssetNumber?: string;
  dates: string[];
  VIN?: string;
  systemTemperatureGraph?: (number | null)[];
  batteryVoltageGraph?: (number | null)[];
  status?: string;
  loadEnergyGraph?: (number | null)[];
  pluggedInTimes?: Date[];
  alerts?: {
    alert: string;
    id: string;
    DCM: string;
    voltage: number;
    shoreline?: number;
    solar?: number;
    load: number;
    temperature: number;
    timestamp: Date | string;
  }[];
  chargingTime?: number;
  loadWattsGraph?: (number | null)[];
  // 48V
  id?: string;
  solarWattsGraph?: (number | null)[];
  solarEnergyGraph?: (number | null)[];
  SOCGraph?: (number | null)[];
  lastVoltage: number | null;
  lastTemperature: number | null;
  lastSOC: number | null;
  lastStealthAmps: number | null;
};

export interface MetricsLoading {
  type: typeof METRICS_LOADING;
}

export interface MetricsSuccess {
  type: typeof METRICS_SUCCESS;
  payload: Array<Metrics>;
}

export type MetricsDispatchTypes = MetricsLoading | MetricsSuccess;
